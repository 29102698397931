<template>
  <div class="flex w-full flex-col">
    <div class="grid grid-cols-1 md:grid-cols-2 md:gap-4 md:mb-10">
      <div class="col-span-1 flex items-center">
        <h1 class="text-18 text-gray-700 font-bold md:mt-10">
          Marketplace
        </h1>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-0">
      <div class="col-span-1">
        <marketplace-category></marketplace-category>
        <template>
          <grid :items="merchants.data" @selected="selectVendor($event)">
            <div class="flex justify-between mt-8">
              <h1 class="text-13 text-gray-700 font-semibold">
                Vendors
              </h1>
              <router-link
                :to="{ name: 'vendors-category' }"
                class="text-13 text-blue-500 cursor-pointer inline-flex font-normal"
              >
                View all vendors
                <ion-icon
                  name="chevron-forward-outline"
                  class="mt-1 text-blue-500"
                ></ion-icon>
              </router-link>
            </div>
            <div class="flex justify-start mt-10" v-if="merchants.loading">
              <sm-loader></sm-loader>
            </div>
          </grid>
        </template>

        <no-result v-if="noResult">
          <p class="text-12 pt-6 pb-6 opacity-50 text-credpal-700">
            No result found for this category
          </p>
        </no-result>
      </div>

      <div
        class="col-span-1 px-0 mt-5 md:mt-0 sm:px-4 md:px-12 lg:px-5 xl:px-24 mb-5"
      >
        <template>
          <div class="flex justify-between">
            <h1 class="text-13 text-gray-700 font-semibold">
              Hot Deals
            </h1>
            <router-link
              :to="{ name: 'vendors-deal' }"
              class="text-13 text-blue-500 cursor-pointer inline-flex font-normal"
            >
              View all deals
              <ion-icon
                name="chevron-forward-outline"
                class="pt-1 text-blue-500"
              ></ion-icon>
            </router-link>
          </div>
          <div class="flex justify-start mt-10" v-if="merchantDeals.loading">
            <sm-loader></sm-loader>
          </div>
          <div class="grid grid-cols-1 gap-4 mt-6">
            <deal
              v-for="(deal, i) in latestDeals"
              :deal="deal"
              :key="i"
              @selectVendor="selectVendor"
            ></deal>
          </div>
          <no-result v-if="noDealFound">
            No result found for this category
          </no-result>
        </template>
      </div>
    </div>

    <branch
      :vendor="selectedVendor"
      v-if="selectedVendor"
      :key="componentKey"
      @success="success"
      ref="branch"
    />
  </div>
</template>
<script>
export default {
  components: {
    NoResult: () => import("./components/NoResult"),
    Grid: () => import("./components/Grid"),
    Branch: () => import("./components/branch/Index"),
    Deal: () => import("./components/Deal")
  },
  data() {
    return {
      merchants: this.$options.resource([]),
      categories: this.$options.resource([]),
      merchantDeals: this.$options.resource([]),
      selectedVendor: null,
      componentKey: 0
    };
  },
  computed: {
    noResult() {
      return !this.merchants.loading && this.merchants.data?.length === 0;
    },
    latestDeals() {
      return this.merchantDeals.data?.slice(0, 6);
    },
    noDealFound() {
      return (
        !this.merchantDeals.loading && this.merchantDeals?.data?.length === 0
      );
    },
    noCategoryFound() {
      return !this.categories.loading && this.categories?.data?.length === 0;
    }
  },
  watch: {
    "selectedVendor.business_name"() {
      this.componentKey += 1;
    }
  },
  beforeMount() {
    this.getAllMerchants();
    this.getFeaturedCategories();
    this.getDeals();
  },
  methods: {
    async getAllMerchants() {
      this.merchants.setLoading();
      await this.sendRequest(
        "personal.marketplace.merchants",
        10,
        "",
        ["featured"],
        {
          success: response =>
            (this.merchants.data = response.data.datatable.data),
          error: error => console.log(error)
        }
      );
      this.merchants.loading = false;
    },
    async getFeaturedCategories() {
      this.categories.loading = true;
      await this.sendRequest(
        "personal.marketplace.categories",
        9,
        "",
        ["featured"],
        {
          success: response =>
            (this.categories.data = response.data.datatable.data),
          error: error => console.log(error)
        }
      );
      this.categories.loading = false;
    },
    async getDeals() {
      this.merchantDeals.loading = true;
      await this.sendRequest("personal.marketplace.deals", {
        success: response => (this.merchantDeals.data = response.data.data),
        error: error => console.log(error)
      });
      this.merchantDeals.loading = false;
    },
    filterWithCategory({ id }) {
      this.$router.push({
        name: "category-vendors",
        params: {
          id: id
        }
      });
    },
    shuffle() {
      this.categories.data.sort(() => {
        return Math.random() - 0.5;
      });
    },
    success() {
      this.componentKey += 1;
      this.$refs.branch.close();
      this.$refs.successModal.openSuccessModal();
    },
    selectVendor(vendor) {
      this.selectedVendor = { ...vendor };
      this.$nextTick(() => {
        this.componentKey += 1;
      });
    }
  }
};
</script>
